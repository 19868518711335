// TODO: Check this again
.v-style-light {
  .v-sidenav {
    @apply border-r border-r-gray-200;

    .v-sidenav-user {
      .v-sidenav-user__subtitle,
      .v-sidenav-user__dropdown-icon {
        @apply text-black/50;
      }

      &:hover {
        @apply bg-gray-100 text-gray-900;
      }
    }

    .v-sidenav-search {
      @apply bg-gray-100 text-gray-600;

      &:hover {
        @apply bg-gray-200 text-gray-900;
      }
    }
  }

  .v-sidenav-user--open {
    @apply bg-gray-100;
  }
}
