@use "@angular/material" as mat;

.v-mat-dense-default {
  $density: -2;
  @include mat.form-field-density($density);
  @include mat.autocomplete-density($density);
  @include mat.select-density($density);
  @include mat.input-density($density);
}

.v-mat-dense-md {
  @include mat.all-component-densities(-1);
}

.v-mat-dense-sm {
  @include mat.all-component-densities(-2);
}

.v-mat-dense-xs {
  @include mat.all-component-densities(-3);
}
