.v-layout-vertical {
  --v-toolbar-width: 100%;

  v-secondary-toolbar {
    .fixed {
      @apply top-0 relative pt-3 -mb-4 bg-none border-t-0 shadow-none;
    }
  }

  &.v-layout-footer-fixed {
    &.v-layout-scroll-disabled .v-layout-content {
      height: calc(100% - var(--v-toolbar-height) - var(--v-footer-height));
    }
  }
}
