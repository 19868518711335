@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// V Core
@import "./app/shared/core/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --v-sidenav-background: #333333;
  }

  All possible variables can be found in @v/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
html {
  // --v-color-primary: blue;
  // --v-color-primary-contrast: white;

  // --v-color-accent: yellow;
  // --v-color-accent-contrast: black;

  // --v-color-warn: yellow;
  // --v-color-warn-contrast: black;
}

.cdk-overlay-container {
  .cdk-global-overlay-wrapper,
  .cdk-overlay-pane {
    direction: rtl;
  }
}

.ql-toolbar {
  text-align: center;
}
.ql-editor {
  text-align: right !important;
}

.ql-tooltip {
  z-index: 1 !important;
  left: unset !important;
}
