.v-layout-hermes {
  --v-toolbar-height: 100px;
  --v-secondary-toolbar-background: var(--v-background-background);
  --v-secondary-toolbar-height: 64px;

  &.v-style-light {
    --v-toolbar-background: var(--v-background-background);
  }

  &.v-style-default {
    // TODO: CHeck this again
    //--v-navigation-background: #{$sidenav-background};
    //--v-toolbar-background: #{lighten($sidenav-background, 5)};
    //--v-navigation-color: white;
  }

  &.v-style-dark {
    --v-toolbar-background: var(--v-background-background);
  }

  // Hide sidenav on vertical layout:
  // Global Search displays it, even when it's out of the view
  @screen lg {
    .v-sidenav {
      @apply hidden;
    }
  }

  v-secondary-toolbar .secondary-toolbar {
    @apply shadow-none -mb-6 border-t-0 border-b-0;
  }
}
