.v-layout-horizontal {
  // Workaround: CSS calc() functions can't deal with values without a unit (e.g. 0 instead of 0px), when this is 0, SCSS or PostCSS make it a 0 instead of 0px, breaking the layout.
  --v-navigation-height: 0.00001px;
  --v-toolbar-width: 100%;

  @screen lg {
    --v-toolbar-width: calc(100% - var(--v-sidenav-width));

    &.v-layout-sidenav-collapsed {
      --v-toolbar-width: calc(100% - var(--v-sidenav-collapsed-width));
    }
  }

  .v-layout-sidenav-container {
    /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
       causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
    flex: 1;
  }

  &.v-layout-footer-fixed {
    &.v-layout-scroll-disabled .v-layout-content {
      height: calc(100% - var(--v-toolbar-height) - var(--v-footer-height));
    }
  }
}

@screen lg {
  body:not([dir="rtl"]) {
    .v-layout-horizontal {
      &.v-layout-sidenav-collapsed .v-layout-sidenav-content {
        margin-left: var(--v-sidenav-collapsed-width) !important;
        margin-right: 0 !important;
      }

      &:not(.v-layout-sidenav-collapsed) .v-layout-sidenav-content {
        margin-left: var(--v-sidenav-width) !important;
        margin-right: 0 !important;
      }
    }
  }

  [dir="rtl"] {
    .v-layout-horizontal {
      &.v-layout-sidenav-collapsed .v-layout-sidenav-content {
        margin-right: var(--v-sidenav-collapsed-width) !important;
        margin-left: 0 !important;
      }

      &:not(.v-layout-sidenav-collapsed) .v-layout-sidenav-content {
        margin-right: var(--v-sidenav-width) !important;
        margin-left: 0 !important;
      }
    }
  }
}
