.v-layout-ares {
  --v-sidenav-background: var(--v-background-background);
  --v-toolbar-background: var(--v-background-background);

  --v-sidenav-color: theme("textColor.default");
  --v-sidenav-section-divider-color: transparent;
  --v-sidenav-item-background-hover: rgb(var(--v-color-primary-600) / 0.1);
  --v-sidenav-item-background-active: var(--v-background-card);
  --v-sidenav-item-color: theme("textColor.default");
  --v-sidenav-item-color-hover: theme("textColor.default");
  --v-sidenav-item-color-active: theme("textColor.default");
  --v-sidenav-item-dropdown-color-active: var(--v-sidenav-item-color-active);
  --v-sidenav-item-dropdown-background-active: var(--v-background-hover);

  --v-sidenav-item-dropdown-background: var(--v-background-background);
  --v-sidenav-item-dropdown-background-hover: var(--v-background-hover);

  --v-secondary-toolbar-background: var(--v-background-background);

  --v-sidenav-toolbar-background: var(--v-background-background);

  --v-secondary-toolbar-height: 64px;

  .v-sidenav {
    border: none;

    v-sidenav-item {
      .v-sidenav-item {
        width: unset;
        @apply rounded-full py-2 mx-4;

        &.v-sidenav-item--active {
          @apply shadow;
        }
      }

      .item-level-0 > .v-sidenav-item {
        @apply my-1;
      }

      .item-level-1 .v-sidenav-item.v-sidenav-item--active {
        @apply shadow-none font-medium;

        .v-sidenav-item__label {
          @apply text-default;
        }
      }

      .item-level-1 .v-sidenav-item:hover .v-sidenav-item__label {
        @apply text-default;
      }
    }
  }

  .v-layout-sidenav-collapsed .v-sidenav v-sidenav-item .v-sidenav-item {
    @apply mx-0;
  }

  v-toolbar {
    @apply border-b-0;
  }

  v-secondary-toolbar {
    @apply rounded-b;

    .secondary-toolbar {
      @apply bg-transparent shadow-none border-t-0 border-b-0 -mb-6;
    }
  }

  v-footer {
    @apply rounded-t overflow-hidden;
  }

  .v-page-layout-header {
    background: var(--v-background-background);
    color: theme("textColor.default");
  }

  &.v-style-default {
    --v-sidenav-item-background-active: theme("backgroundColor.primary.600");
    --v-sidenav-item-color-active: theme("textColor.on-primary.600");
    --v-sidenav-item-dropdown-color-active: var(--v-sidenav-item-color);
    --v-sidenav-item-dropdown-color-hover: theme("textColor.default");
    --v-sidenav-item-icon-color-active: theme("textColor.on-primary.600");
  }
}
