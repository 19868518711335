// @formatter:off
body {
  // Typography
  --v-font: theme("fontFamily.sans");

  // Sidenav
  --v-sidenav-width: 280px;
  --v-sidenav-collapsed-width: 72px;

  // Sidenav Item
  --v-sidenav-item-min-height: 48px;
  --v-sidenav-item-padding-vertical: theme("space.2");
  --v-sidenav-item-padding-horizontal: theme("space.6");
  --v-sidenav-item-font-weight: 500;
  --v-sidenav-item-icon-gap: theme("space.4");
  --v-sidenav-item-icon-size: theme("space.6");
  --v-sidenav-item-dropdown-gap: theme("space.3");

  // Toolbar
  --v-toolbar-height: 64px;

  // Secondary Toolbar
  --v-secondary-toolbar-height: 54px;

  // Navigation
  --v-navigation-height: 64px;

  // Footer
  --v-footer-height: 56px;
  --v-footer-z-index: 100;
  --v-footer-elevation: 0 -10px 30px 0 rgba(82, 63, 104, 0.06);

  // Page Layouts
  --v-page-layout-header-height: 200px;
  --v-page-layout-toolbar-height: 64px;

  // Misc
  --v-blink-scrollbar-width: 12px;
  --v-default-icon-size: 24px;
  --v-border-radius: 0.25rem;
}
// @formatter:on
